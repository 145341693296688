import React, { Component } from "react"
import { Link } from "gatsby"
import SiteContext from "../context/SiteContext"
import FixedMenu from "../components/FixedMenu" 
import ImageCard from "../components/ImageCard"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import SEO from "../components/seo"

class SystemFamiliesPage extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props) 
        this.state = {
            site: ''
        }
    }

    componentDidMount() {
        let site = 'senergy'
        
        this.context.changeSite(site)
        this.setState({ site })
    }

    render() {

        const { systemData, systemFamilyData } = this.props.pageContext
        let systemFamilies = systemFamilyData.data.craft.categories

        return ( 
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Systems" />
                        <div className="wrapper app-header-style-text">
                            <div className="content-wrapper--padded-bottom">
                                <div className="app-header">
                                    <div className="app-header-content">
                                        <h1 className="app-header__title">Systems</h1> 
                                    </div>
                                </div>
                                <div className="image-card__wrapper">
                                {systemFamilies.map(system => {
                                    if (system.level == 1) {
                                        return (
                                        <ImageCard 
                                            imagePath={`url(${system.imagePath}?width=940)`}
                                            url={`/systems/${system.slug}`}
                                            title={system.title}
                                        />
                                        )
                                    }
                                })}
                                </div>
                            </div>
                            <FixedMenu active="systems" site={siteContext.site} /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default SystemFamiliesPage