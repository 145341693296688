import React, { Component } from 'react'
import { navigate } from '@reach/router'
import TextField from '@material-ui/core/TextField';
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
 

class FixedMenu extends Component {

    // static contextType = SiteContext;

    constructor(props) {
        super(props);
    }

    render() {
        let height = 0;

        // if (window){
        //     height = window.innerHeight + window.pageYOffset - 91;
        // }

        let page = this.props.active || '';

        // let animationType = "fade";
        // if (page === 'home'){
        //     animationType = "swipe";
        // }

        return ( 
            <div className={`fixed-menu ${ page === 'home' ? 'isHome' : ''}`}>
                <div className="fixed-menu__inner">
                    <AniLink fade to='/welcome' className={`fixed-menu__link ${ page === 'home' ? 'active' : ''}`}>
                        <div className="fixed-menu__icon"> <IcomoonReact iconSet={iconSet} size={31} icon="home" /> </div>
                        <span className="fixed-menu__title">Home</span> 
                    </AniLink>
                    <AniLink fade to='/systems' className={`fixed-menu__link ${ page === 'systems' ? 'active' : ''}`}>
                        <div className="fixed-menu__icon"> <IcomoonReact iconSet={iconSet} size={31} icon="systems" /> </div>
                        <span className="fixed-menu__title">Systems</span> 
                    </AniLink>
                    <AniLink fade to='/products' className={`fixed-menu__link ${ page === 'products' ? 'active' : ''}`}>
                        <div className="fixed-menu__icon"> <IcomoonReact iconSet={iconSet} size={31} icon="products" /> </div>
                        <span className="fixed-menu__title">Products</span> 
                    </AniLink>
                    <AniLink fade to='/videos' className={`fixed-menu__link ${ page === 'videos' ? 'active' : ''}`}>
                        <div className="fixed-menu__icon"> <IcomoonReact iconSet={iconSet} size={31} icon="video-player" /> </div>
                        <span className="fixed-menu__title">Videos</span> 
                    </AniLink>
                    <AniLink fade to='/literature' className={`fixed-menu__link ${ page === 'literature' ? 'active' : ''}`}>
                        <div className="fixed-menu__icon"> <IcomoonReact iconSet={iconSet} size={31} icon="documents" /> </div>
                        <span className="fixed-menu__title">Literature</span>
                    </AniLink>
                </div>   
            </div>
        )
    }
}


export default FixedMenu