import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Button from '@material-ui/core/Button'
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import AniLink from "gatsby-plugin-transition-link/AniLink"


class ImageCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AniLink direction="left" swipe duration={.2}
            top="entry" to={this.props.url} className="image-card" style={{backgroundImage: this.props.imagePath }}>
                    <div className="image-card__overlay"></div>
                    <div className="image-card__content">
                        <div>
                            <div className="image-card__title">{this.props.title}</div>
                            <div className="image-card__subtitle">{this.props.subtitle }</div>
                        </div>
                        
                        <span className="image-card__icon"> <IcomoonReact iconSet={iconSet} size={30} icon="arrow-right" color="#313131"/> </span>
                    </div>
            </AniLink>
        );
    }
}

export default ImageCard;
